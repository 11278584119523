<template>
  <a
    v-if="this.shouldDisplay() === true"
    :href="this.imageLink()"
    class="ResultsAd"
  >
    <img
      :id="uniqueId"
      :src="this.imageSrc()"
      class="column is-record-ad is-hidden-mobile has-text-centered"
      alt="advertisemnt"
    >
  </a>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    index: {
      type: Number,
      required: false,
    },
    adEveryXRecords: {
      type: Number,
      required: false,
      default: 4,
    },
  },
  data() {
    return {
      uniqueId: `result-ad-${window.performance.now()}-${Math.random().toString(36).substring(7)}`,
    };
  },
  computed: {
    ...mapState('advertisements', {
      ads: (state) => state.results_ads,
    }),
  },
  methods: {
    shouldDisplay() {
      return !!(// if
        this.ads?.length // there are ads
        && this.index > 0 // the index is not 0
        && this.index % this.adEveryXRecords === 0 // is congruent with 4
        && this.index / this.adEveryXRecords <= this.ads?.length // and has the correct count
      );
    },
    imageSrc() {
      return Array.from(this.ads)[this.index / this.adEveryXRecords - 1].src;
    },
    imageLink() {
      return Array.from(this.ads)[this.index / this.adEveryXRecords - 1].link;
    },
  },
};
</script>
